
import { ApiIcon, EBSIcon, EmrIcon, LdapIcon, PeopleSoftIcon, WebIcon } from '../component/CustomIcon/CustomIcon';

export const VERSION = '2.3.0';
export const API_VERSION = 'v1';
export const DOMAIN = '';
export const MERLIN = 'merlin';
export const DEFAULT_PAGE_SIZE = 10;
export const CONFIG = window.config;
export const INTERNAL_ERROR_CODE = '500';


export enum FieldTagTypesEnum {
  URL = 'URL',
  GROUP = 'GROUP',
  TIME = 'TIME',
  IP = 'IP',
  USER_ATTR = 'USER_ATTR',
  EMAIL = 'EMAIL',
  SCOPE = 'SCOPE',
}

export enum ConditionalAccessPolicyTypeEnum {
  MFA_FOR_EVERYONE = 'MFA_FOR_EVERYONE',
  MFA_FOR_ADMIN = 'MFA_FOR_ADMIN',
  MFA_FOR_MEDIUM_RISK = 'MFA_FOR_MEDIUM_RISK',
  REQUIRE_TRUST_DEVICE = 'REQUIRE_TRUST_DEVICE',
}

export enum SSLModeEnum {
  UPLOAD = 'UPLOAD',
  SELF_SIGNED = 'SELF_SIGNED',
}

export enum SSOProvidersEnum {
  OKTA = 'OKTA',
  AZURE = 'AZURE',
  PING = 'PING',
  AUTH0 = 'AUTH0',
  GOOGLE = 'GOOGLE',
  KEYCLOAK = 'KEYCLOAK',
  GSUITE = 'GSUITE',
  B2C = 'B2C',
  COGNITO = 'COGNITO',
  GENERIC = 'GENERIC',
  LDAP = 'LDAP',
  AD = 'AD',
  TWITTER = 'TWITTER',
  GITHUB = 'GITHUB',
  AAD_GOV = 'AAD_GOV',
  EXTERNAL = 'EXTERNAL'
}

export enum MfaProvidersEnum {
  DUO = 'DUO'
}

export enum SSOProtocolEnum {
  OIDC = 'OIDC',
  SAML = 'SAML',
  LDAP = 'LDAP',
  OAUTH = 'OAUTH',
  AD = 'AD',
}

export const PROTOCOL_NAMES = {
  OIDC: {
    name: 'OIDC',
    disabled: false,
  },
  SAML: {
    name: 'SAML',
    disabled: false,
  },
  LDAP: {
    name: 'LDAP',
    disabled: false,
  },
  OAUTH: {
    name: 'OAUTH',
    disabled: false,
  },
  AD: {
    name: 'Active Directory',
    disabled: true,
  }
};

export enum PlatformEnum {
  WEB = 'WEB',
  API = 'API',
  EMR = 'EMR',
  EBS = 'EBS',
  PS = 'PS',
  AIS = 'AIS',
  JDE = 'JDE',
  LDAP = 'LDAP'
}

export enum ActivityTypeEnum {
  CONFIG = 'CONFIG',
  SYSTEM = 'SYSTEM',
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  UPLOAD = 'UPLOAD',
}

export enum OrgSubscription {
  FREE = 'FREE',
  PAID = 'PAID'
}

export const BASE_IDP_ATTRIBUTES = ['username', 'lastName', 'email', 'firstName', 'groups', 'sAMAccountName'];

const OKTA_CONFIG = {
  name: 'Okta',
  issuer: '',
  scopes: 'profile,email,openid,offline_access',
};

const AZURE_CONFIG = {
  name: 'Microsoft Entra ID',
  issuer: '',
  scopes: 'profile,openid,email,offline_access,user.read',
};

const AZURE_GOV_CONFIG = {
  name: 'Microsoft Entra ID for Government',
  issuer: '',
  scopes: 'profile,openid,email,offline_access,user.read',
};

const EXTERNAL_CONFIG = {
  name: 'Microsoft Entra External ID',
  issuer: '',
  scopes: 'profile,openid,email,offline_access',
};

const B2C_CONFIG = {
  name: 'Microsoft Azure AD B2C',
  issuer: '',
  scopes: 'profile,openid,email,offline_access',
};

const AUTH0_CONFIG = {
  name: 'Auth0',
  issuer: '',
  scopes: 'profile,email,openid,offline_access',
};

const KEYCLOAK_CONFIG = {
  name: 'Keycloak',
  issuer: '',
  scopes: 'profile,email,openid,offline_access',
};

const COGNITO_CONFIG = {
  name: 'Cognito',
  issuer: '',
  scopes: 'profile,email,openid',
};

const GENERIC_CONFIG = {
  name: 'Generic',
  issuer: '',
  scopes: 'profile,email,openid,offline_access',
};

const PING_CONFIG = {
  name: 'Ping One',
  issuer: '',
  scopes: 'profile,openid,email,offline_access,p1:read:user',
};

const GOOGLE_CONFIG = {
  name: 'Google',
  issuer: 'https://accounts.google.com',
  scopes: 'profile,email,openid',
};

const GSUITE_CONFIG = {
  name: 'Google Workspace',
  issuer: 'https://accounts.google.com',
  scopes: 'profile,openid,email',
};

const LDAP_CONFIG = {
  name: 'LDAP',
  issuer: '',
  scopes: '',
};

const AD_CONFIG = {
  name: 'Active Directory',
  issuer: '',
  scopes: '',
};

const TWITTER_CONFIG = {
  name: 'Twitter',
  issuer: 'https://api.twitter.com',
  scopes: null,
};

const GITHUB_CONFIG = {
  name: 'Github',
  issuer: 'https://github.com',
  scopes: 'user:email,read:org',
};

export const PROVIDERS: {
  
  [key: string]: {
    [key: string]: { name: string; issuer: string; disabled?: boolean; scopes: string | null };
  };
} = {
  OAUTH: {
    GITHUB: GITHUB_CONFIG,
    TWITTER: TWITTER_CONFIG,
  },
  LDAP: {
    LDAP: LDAP_CONFIG,
  },
  AD: {
    AD: AD_CONFIG,
  },
  OIDC: {
    AUTH0: AUTH0_CONFIG,
    COGNITO: COGNITO_CONFIG,
    GOOGLE: GOOGLE_CONFIG,
    GSUITE: GSUITE_CONFIG, 
    KEYCLOAK: KEYCLOAK_CONFIG,
    B2C: B2C_CONFIG,
    EXTERNAL: EXTERNAL_CONFIG,
    AZURE: AZURE_CONFIG,
    AAD_GOV: AZURE_GOV_CONFIG,
    OKTA: OKTA_CONFIG,
    PING: PING_CONFIG,
    // generic
    GENERIC: GENERIC_CONFIG, 
  },
  SAML: {
    GENERIC: GENERIC_CONFIG,
    OKTA: OKTA_CONFIG,
    AZURE: AZURE_CONFIG,
    AUTH0: AUTH0_CONFIG,
  },
  BASIC_AUTH: {
    OKTA: OKTA_CONFIG,
  },
  JWT: {
    OKTA: OKTA_CONFIG,
    AZURE: AZURE_CONFIG,
    B2C: B2C_CONFIG,
    AUTH0: AUTH0_CONFIG,
    KEYCLOAK: KEYCLOAK_CONFIG,
  },
};

export const PLATFORMS: Record<string, { icon: any; title: string; subTitle: string; disabled: boolean }> = {
  [PlatformEnum.WEB]: {
    icon: WebIcon,
    title: 'WEB',
    subTitle: 'JAVA, .NET, etc.',
    disabled: false,
  },
  [PlatformEnum.EBS]: {
    icon: EBSIcon,
    title: 'Oracle E-Business Suite',
    subTitle: 'Oracle E-Business Suite',
    disabled: false
  },
  [PlatformEnum.PS]: {
    icon: PeopleSoftIcon,
    title: 'Oracle PeopleSoft',
    subTitle: 'Oracle PeopleSoft',
    disabled: false
  },
  [PlatformEnum.JDE]: {
    icon: PeopleSoftIcon,
    title: 'Oracle JD Edwards EnterpriseOne',
    subTitle: 'Oracle JD Edwards EnterpriseOne',
    disabled: false
  },
  [PlatformEnum.AIS]: {
    icon: PeopleSoftIcon,
    title: 'Oracle Application Interface Service (AIS)',
    subTitle: 'Oracle PeopleSoft',
    disabled: false
  },
  [PlatformEnum.EMR]: {
    icon: EmrIcon,
    title: 'AWS Elastic Map Reduce',
    subTitle: 'AWS Elastic Map Reduce',
    disabled: false
  },
  [PlatformEnum.API]: {
    icon: ApiIcon,
    title: 'API',
    subTitle: 'Machine-to-Machine',
    disabled: false
  },
  [PlatformEnum.LDAP]: {
    icon: LdapIcon,
    title: 'LDAP Proxy',
    subTitle: 'LDAP Proxy',
    disabled: true
  },
};

export enum AzureAccountSignInAudienceEnum {
  AZURE_AD_MY_ORG = 'AzureAdMyOrg',
  AZURE_AD_MULTIPLE_ORGS = 'AzureAdMultipleOrgs',
  AZURE_AD_AND_PERSONAL_MICROSOFT_ACCOUNT = 'AzureAdAndPersonalMicrosoftAccount',
  PERSONAL_MICROSOFT_ACCOUNT = 'PersonalMicrosoftAccount'
}

export enum AzureAccountSignInAudienceForBackendEnum {
  AZURE_AD_MY_ORG = 'AZURE_AD_MY_ORG',
  AZURE_AD_MULTIPLE_ORGS = 'AZURE_AD_MULTIPLE_ORGS',
  AZURE_AD_AND_PERSONAL_MICROSOFT_ACCOUNT = 'AZURE_AD_AND_PERSONAL_MICROSOFT_ACCOUNT',
  PERSONAL_MICROSOFT_ACCOUNT = 'PERSONAL_MICROSOFT_ACCOUNT'
}

export enum AbComponentEnum {
  RP = 'RP',
  FILEBEAT = 'FILEBEAT',
  CONNECTOR = 'CONNECTOR',
  LDAP = 'LDAP',
}

export enum AbComponentTypeEnum {
  RP = 'RP',
  FILEBEAT = 'FILEBEAT',
  CONNECTOR = 'CONNECTOR',
  LDAP = 'LDAP',
}

export enum AttrMappingTypeEnum {
  DIRECT = 'DIRECT',
  EXPRESSION = 'EXPRESSION',
  EXTRACT_USERNAME = 'EXTRACT_USERNAME'
}

export const ATTR_MAPPING_TYPE: Record<string, any> = {
  [AttrMappingTypeEnum.DIRECT]: {
    name: 'Direct',
  },
  [AttrMappingTypeEnum.EXPRESSION]: {
    name: 'Expression',
  },
  [AttrMappingTypeEnum.EXTRACT_USERNAME]: {
    name: 'Extract Username before @ sign',
  }
};

export enum ActivityStatusEnum {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE'
}

export enum IdPTypeEnum {
  GENERAL = 'GENERAL',
  B2B_SSO = 'B2B_SSO',
}

export enum JwtSecretOptionEnum {
  PROVISIONING_SECRET = 'PROVISIONING_SECRET',
  CUSTOM_SECRET = 'CUSTOM_SECRET'
}

export enum RuleType {
  ACCESS = 'ACCESS',
  REDIRECT = 'REDIRECT'
}

export const LOGIC_LIST: { [key: string]: any } = {
  ALLOW: 'Allow',
  DENY: 'Deny',
};

export const AB_STATUS: { [key: string]: any } = {
  OK: 'Running',
  WARNING: 'Warning',
  ERROR: 'Error',
  INACTIVE: 'Inactive',
};

export const RULE_TYPE: { [key: string]: any } = {
  PROTECTED: 'Protected',
  AUTH: 'Authorization',
  // UNAUTH: 'Protect without Authentication',
  UNPROTECTED: 'Not Protected',
};

export const SERVICE_RULE_TYPE: { [key: string]: any } = {
  AUTH: 'Authorization',
  UNPROTECTED: 'Not Protected',
};

export const AUDIT_LOG_TYPE: Array<{ [key: string]: any }> = [
  {
    Login: {
      LOGIN_SUCCESS: 'Login successful',
      // LOGIN_FAIL: 'Login failed',
    },
    ['Admin Token']: {
      TOKEN_CREATED: 'Admin Token created',
      TOKEN_REVOKED: 'Admin Token revoked',
    },
  },
  {
    Application: {
      APP_CREATED: 'Application created',
      APP_UPDATED: 'Application updated',
      APP_DELETED: 'Application deleted',
      RULE_CREATED: 'Rule created',
      RULE_UPDATED: 'Rule updated',
      RULE_DELETED: 'Rule deleted',
      IDP_ASSIGNED: 'Identity Provider assigned',
      IDP_REMOVED: 'Identity Provider removed',
      ATTR_MAPPING_UPDATED: 'Attribute mappings updated',
    },
  },
  {
    Deployment: {
      DEPLOY_CREATED: 'Deployment created',
      DEPLOY_UPDATED: 'Deployment updated',
      DEPLOY_DELETED: 'Deployment deleted',
      KEY_CREATED: 'Provisioning Key created',
      KEY_REVOKED: 'Provisioning Key revoked',
      // LDAP_CREATED: 'LDAP created',
      LDAP_UPDATED: 'LDAP updated',
      LDAP_DELETED: 'LDAP deleted',
    },
  },
  {
    IdP: {
      IDP_CREATED: 'Identity Provider created',
      IDP_UPDATED: 'Identity Provider updated',
      IDP_DELETED: 'Identity Provider deleted',
    },
    Authorizer: {
      AUTHORIZER_CREATED: 'Authorizer created',
      AUTHORIZER_UPDATED: 'Authorizer updated',
      AUTHORIZER_DELETED: 'Authorizer deleted',
    },
    LDAP: {
      LDAP_MAPPING_CREATED: 'LDAP mapping created',
      LDAP_MAPPING_DELETED: 'LDAP mapping deleted',
      LDAP_MAPPING_UPDATED: 'LDAP mapping updated',
    },
  }];


export const AZURE_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  userPrincipalName: 'Email',
  name: 'User name',
  givenName: 'First Name',
  surname: 'Last Name',
  displayName: 'Name',
  jobTitle: 'Job Title',
  department: 'Department',
  groups: 'Group',
  mail: 'Mail',
  mailNickName: 'Mail Nickname',
  employeeId: 'Employee Id',
  onPremisesSamAccountName: 'On-premises SAM account name',
  onPremisesUserPrincipalName: 'On-premises user principal name'
};

export const AUTH0_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  username: 'User name',
  ['given_name']: 'First name',
  ['family_name']: 'Last name',
  nickname: 'Nick name',
  ['phone_number']: 'Phone number',
};

export const GOOGLE_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  ['given_name']: 'First name',
  ['family_name']: 'Last name',
  name: 'Name',
  nickname: 'Nick name',
  locale: 'Locale',
};

export const B2C_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  emails: 'Email',
  ['given_name']: 'First name',
  ['family_name']: 'Last name',
  name: 'Name',
  city: 'City',
  state: 'State',
  country: 'Country',
  jobTitle: 'Job Title',
  postalCode: 'Postal Code',
};

export const EXTERNAL_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  ['given_name']: 'First name',
  ['family_name']: 'Last name',
  name: 'Name',
  city: 'City',
  state: 'State',
  country: 'Country',
  jobTitle: 'Job Title',
  postalCode: 'Postal Code',
};

export const GSUITE_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  primaryEmail: 'Email',
  givenName: 'First name',
  familyName: 'Last name',
  fullName: 'Full name',
  groups: 'Group',
};

export const KEYCLOAK_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  username: 'User name',
  firstName: 'First name',
  lastName: 'Last name',
  groups: 'Group',
};

export const PING_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  username: 'User name',
  firstName: 'First name',
  lastName: 'Last name',
  groups: 'Group',
};

export const GENERIC_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  username: 'Username',
  ['given_name']: 'First name',
  ['family_name']: 'Last name',
  groups: 'Group',
};

export const TWITTER_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  username: 'Username',
};

export const GITHUB_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  username: 'Username',
};

export const COGNITO_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Email',
  ['given_name']: 'First name',
  ['family_name']: 'Last name',
  ['cognito:username']: 'Username',
  nickname: 'Nick name',
  locale: 'Locale',
  ['cognito:groups']: 'Group',
  ['phone_number']: 'Mobile phone',
};

export const OKTA_DEFAULT_USER_ATTRIBUTES: { [key: string]: string } = {
  email: 'Primary email',
  login: 'User name',
  groups: 'Group',
  firstName: 'First name',
  lastName: 'Last name',
  middleName: 'Middle name',
  title: 'Title',
  displayName: 'Display name',
  nickName: 'Nickname',
  mobilePhone: 'Mobile phone',
  city: 'City',
  state: 'State',
  zipCode: 'Zip code',
  timezone: 'Time zone',
  userType: 'User type',
  organization: 'Organization',
  division: 'Division',
  department: 'Department',
  manager: 'Manager',
};

export const DEFAULT_USER_ATTRIBUTES: Record<SSOProvidersEnum, any> = {
  [SSOProvidersEnum.AUTH0]: AUTH0_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.AZURE]: AZURE_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.AAD_GOV]: AZURE_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.GOOGLE]: GOOGLE_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.OKTA]: OKTA_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.PING]: PING_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.KEYCLOAK]: KEYCLOAK_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.GSUITE]: GSUITE_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.B2C]: B2C_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.COGNITO]: COGNITO_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.GENERIC]: GENERIC_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.LDAP]: GENERIC_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.AD]: GENERIC_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.GITHUB]: GITHUB_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.TWITTER]: TWITTER_DEFAULT_USER_ATTRIBUTES,
  [SSOProvidersEnum.EXTERNAL]: EXTERNAL_DEFAULT_USER_ATTRIBUTES,
};

export const COMMON_USER_ATTRIBUTES = {
  groups: 'Groups',
  department: 'Department',
};

export const DEFAULT_POLICES_FOR_ASSIGN: { [key: string]: any } = {
  [ConditionalAccessPolicyTypeEnum.MFA_FOR_EVERYONE]: {
    name: 'Require MFA for Everyone',
    users: 'All users',
    conditions: 'N/A',
    grant: 'Require multi-factor authentication',
  },
  [ConditionalAccessPolicyTypeEnum.MFA_FOR_ADMIN]: {
    name: 'Require MFA for Privileged Accounts',
    users: 'All administrators',
    conditions: 'N/A',
    grant: 'Require multi-factor authentication',
  },
  [ConditionalAccessPolicyTypeEnum.MFA_FOR_MEDIUM_RISK]: {
    name: 'Require MFA for Medium or higher risk',
    users: 'All users',
    conditions: 'Medium or higher risk',
    grant: 'Require multi-factor authentication',
  },
  [ConditionalAccessPolicyTypeEnum.REQUIRE_TRUST_DEVICE]: {
    conditions: 'N/A',
    name: 'Enforce Compliant Device',
    users: 'All users',
    grant: 'Require trust device authentication',
  },
};

export const IDP_ICON_SRC: Record<SSOProvidersEnum | MfaProvidersEnum, any> = {
  [SSOProvidersEnum.AUTH0]: require('../assets/idp_logos/colored/auth0.png'),
  [SSOProvidersEnum.AZURE]: require('../assets/idp_logos/colored/azure.png'),
  [SSOProvidersEnum.GOOGLE]: require('../assets/idp_logos/colored/google.png'),
  [SSOProvidersEnum.OKTA]: require('../assets/idp_logos/colored/okta.png'),
  [SSOProvidersEnum.PING]: require('../assets/idp_logos/colored/ping.jpeg'),
  [SSOProvidersEnum.KEYCLOAK]: require('../assets/idp_logos/colored/keycloak.png'),
  [SSOProvidersEnum.GSUITE]: require('../assets/idp_logos/colored/google.png'),
  [SSOProvidersEnum.B2C]: require('../assets/idp_logos/colored/b2c.png'),
  [SSOProvidersEnum.COGNITO]: require('../assets/idp_logos/colored/cognito.png'),
  [SSOProvidersEnum.GENERIC]: require('../assets/idp_logos/colored/generic.png'),
  [SSOProvidersEnum.LDAP]: require('../assets/idp_logos/colored/ldap.jpeg'),
  [SSOProvidersEnum.AD]: require('../assets/idp_logos/colored/ad.png'),
  [SSOProvidersEnum.TWITTER]: require('../assets/idp_logos/colored/twitter.png'),
  [SSOProvidersEnum.GITHUB]: require('../assets/idp_logos/colored/github.png'),
  [SSOProvidersEnum.AAD_GOV]: require('../assets/idp_logos/colored/azure.png'),
  [SSOProvidersEnum.EXTERNAL]: require('../assets/idp_logos/colored/external.png'),
  [MfaProvidersEnum.DUO]: require('../assets/idp_logos/colored/duo.jpg')
};

export const MAX_BODY_SIZE: string[] = ['100K', '200K', '500K', '1M', '2M',
  '4M', '8M', '16M', '30M', '60M', '100M', '200M', '500M', '1G', '2G', '4G', '8G', '16G'];


export const CLIENT_ID = CONFIG.CLIENT_ID ? CONFIG.CLIENT_ID : '4f011d0f-44d4-4c42-ad4c-88c7bbcd1ac8';
export const TENANT_ID = CONFIG.TENANT_ID ? CONFIG.TENANT_ID : 'df3f213b-68db-4966-bee4-c826eea4a310';
export const TENANT_NAME = CONFIG.TENANT_NAME ? CONFIG.TENANT_NAME : 'datawizab2cprod';
export const B2C_DOMAIN = CONFIG.B2C_DOMAIN ? CONFIG.B2C_DOMAIN : 'https://login.datawiza.com';

export const LOGIN_REDIRECT_URL = `${window.location.origin}/home`;

export const B2C_ID_TOKEN_ACR = ['b2c_1a_linkage', 'b2c_1a_okta_customsso'];
export const SIGN_IN_AUTHORITY = `${B2C_DOMAIN}/${TENANT_ID}/B2C_1A_linkage`;
export const EDIT_PROFILE_AUTHORITY = `${B2C_DOMAIN}/${TENANT_ID}/B2C_1A_ProfileEdit`;
export const RESET_PASSWORD_AUTHORITY = `${B2C_DOMAIN}/${TENANT_ID}/B2C_1A_PasswordReset`;
export const CHANGE_PASSWORD_AUTHORITY = `${B2C_DOMAIN}/${TENANT_ID}/B2C_1A_PasswordChange`;
export const OKTA_CUSTOM_SSO_AUTHORITY = `${B2C_DOMAIN}/${TENANT_ID}/B2C_1A_OKTA_CustomSSO`;
export const REFRESH_TOKEN_SCOPES = [`https://${TENANT_NAME}.onmicrosoft.com/${CLIENT_ID}/ReadWrite.All`];
export const AZURE_CONDITIONAL_ACCESS_SCOPES = ['Policy.ReadWrite.ConditionalAccess', 'Policy.Read.All', 'Application.ReadWrite.All'];
export const AZURE_ONE_CLICK_SCOPES = ['Application.ReadWrite.All'];

export const AMPLITUDE_ID = CONFIG.AMPLITUDE_ID ? CONFIG.AMPLITUDE_ID : '34fe677fffda3ae5660bcce5c99a6315';
