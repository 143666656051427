import { Modal } from 'antd';
import { action, observable } from 'mobx';
import { IdPTypeEnum, PlatformEnum, RuleType, SSLModeEnum } from '../common/constants';
import { calExpireTime, navigateInLayout, removeEmpty } from '../common/util';
import { graphAPIServices } from '../services/graphAPIServices';
import { ApplicationFormInputs } from '../services/model/application';
import { AuthorizerFormInputs } from '../services/model/authorizer';
import { DeploymentFormInputs, ProvisioningKeyFormInputs } from '../services/model/deployment';
import { CreateIdpParams, IdpFormInputs } from '../services/model/idp';
import Services from '../services/services';
import { idpsStore } from './idpsStore';
import { sslStore } from './sslStore';

const { confirm } = Modal;

export type AddDeploymentInputs = {
  deployment: DeploymentFormInputs;
  application: ApplicationFormInputs;
  provisioningKey: ProvisioningKeyFormInputs;
  idp: IdpFormInputs;
  authorizer: AuthorizerFormInputs;
}

const defaultAddDeploymentInputValues: AddDeploymentInputs = {
  deployment: {
    name: '',
  },
  application: {
    name: '',
    platform: PlatformEnum.WEB,
    publicDomain: '',
  },
  provisioningKey: {
    expires: 1,
  },
  idp: {
    name: '',
    protocol: null,
    automaticGenerator: true,
    protocolProperties: {},
  },
  authorizer: {
    name: '',
    provider: undefined,
    urls: [],
    issuer: '',
    type: 'BASIC_AUTH'
  }

};

export class AddDeploymentStore {
  private static instance: AddDeploymentStore;

  public static getInstance(): AddDeploymentStore {
    if (!AddDeploymentStore.instance) {
      AddDeploymentStore.instance = new AddDeploymentStore();
    }

    return AddDeploymentStore.instance;
  }
  @observable currentStep = 1;
  @observable deploymentId = '';

  @observable deploymentData: AddDeploymentInputs = defaultAddDeploymentInputValues;

  @observable loading = false;
  @observable keyPair: { provisionKey: string | undefined; provisionSecret: string | undefined } = { provisionKey: undefined, provisionSecret: undefined };

  @action
  init() {
    this.currentStep = 1;
    this.deploymentData = defaultAddDeploymentInputValues;
  }

  @action
  setDeploymentData = (value: Partial<AddDeploymentInputs>): void => {
    this.deploymentData = { ...this.deploymentData, ...value };
  }

  @action
  setCurrentStep = (value: number): void => {
    this.currentStep = value;
  }

  @action
  cancelCreate = (): void => {
    confirm({
      title: 'Do you want to abort this integration?',
      content: 'The app information won\'t be saved.',
      okButtonProps: { danger: true },
      okText: 'Abort',
      onOk: () => {
        this.currentStep = 1;
        this.deploymentData = defaultAddDeploymentInputValues;
        navigateInLayout('/home');
      },
    });
  }

  // TODO: if something went wrong, it should revert previous action
  async createDeployment(azureId?: string) {
    let createDeploymentRes; let createApplicationRes; let createIdpRes; let createProvisionKeyRes;
    let createAuthorizerRes;

    try {
      createDeploymentRes = await Services.createDeployment({
        ...this.deploymentData.deployment
      });

      createApplicationRes = await Services.createApplication({
        ...this.deploymentData.application,
        upstreams: removeEmpty(this.deploymentData.application.upstreams),
        listenPort: this.deploymentData.application.listenPort || 80,
        deploymentId: createDeploymentRes.data.id,
      });

      if (this.deploymentData.application.enableSSL && this.deploymentData.application.enableSSL[0] === 'ENABLED_SSL') {
        await sslStore.updateSSL({
          isSSL: true,
          mode: SSLModeEnum.SELF_SIGNED
        }, createApplicationRes.data.id);
      }

      if (this.deploymentData.application.platform === PlatformEnum.API) {
        createAuthorizerRes = await Services.createAuthorizer({
          ...this.deploymentData.authorizer,
          audiences: this.deploymentData.authorizer.audience ? [this.deploymentData.authorizer.audience] : undefined,
        });
        await Services.createRule({
          name: 'Custom Rule - ' + this.deploymentData.authorizer.name,
          resources: this.deploymentData.authorizer.urls.map((url: string) => {
            return { url, logic: 'PREFIX', method: 'ANY' };
          }),
          protectType: 'AUTH',
          priority: 1,
          action: 'ALLOW',
          authorizerIds: [createAuthorizerRes.data.id],
          appId: createApplicationRes.data.id,
          ruleType: RuleType.ACCESS
        });
      } else {
        createIdpRes = await idpsStore.createIdp(this.deploymentData.idp as CreateIdpParams);

        await Services.assignIdP({
          idpId: createIdpRes.data.id,
          appId: createApplicationRes.data.id,
          type: IdPTypeEnum.GENERAL,
        });
      }

      createProvisionKeyRes = await Services.createProvisionKey({
        name: 'Default Key',
        expire: calExpireTime(1),
        deploymentId: createDeploymentRes.data.id,
      });

      this.keyPair.provisionKey = createProvisionKeyRes.data.key;
      this.keyPair.provisionSecret = createProvisionKeyRes.data.secret;

      this.deploymentId = createDeploymentRes.data.id;
      this.setCurrentStep(this.currentStep + 1);
    } catch (e) {
      try {
        if (createDeploymentRes && createDeploymentRes.data.id) {
          Services.deleteDeployment({ deploymentId: createDeploymentRes.data.id, cascadeDeleteIdps: true });
        }
        if (createIdpRes) {
          Services.deleteIdP({ idpId: createIdpRes.data.id });
        }
        if (createAuthorizerRes) {
          Services.deleteAuthorizer({ authorizerId: createAuthorizerRes.data.id });
        }
        if (azureId) {
          graphAPIServices.deleteApplicationInAzure(azureId);
        }
      } catch (error) {
        throw error;
      }
      throw e;
    }
  }
}

export const addDeploymentStore = AddDeploymentStore.getInstance();
