import * as API from '../common/api';
import { AbComponentEnum, ActivityStatusEnum, ActivityTypeEnum, HttpMethod, IdPTypeEnum } from '../common/constants';
import { GetApplicationDetailParams, GetAppRulesParams } from '../types/app';
import { RESOURCE } from './fetch/fetch';
import * as APITokenMgmt from './model/apiToken';
import * as ApplicationMgmt from './model/application';
import * as AttributeMgmt from './model/attribute';
import { AuthorizersRes, CreateAuthorizer } from './model/authorizer';
import * as BaseMgmt from './model/base';
import * as DeploymentMgmt from './model/deployment';
import * as IdPMgmt from './model/idp';
import { CreateLdapParams, CreateLdapTestCaseParams, LdapMapping, LdapMappingsRes, LdapRes, LDAPsRes, UpdateLdapParams } from './model/ldap';
import { CreateMfaRes, GetMFAsParams, MFAsOfAppRes, MFAsRes } from './model/mfa';
import { CreateOtpGroupParams, GetOtpGroupDetailRes } from './model/mfaGroup';
import { CreateOtpUserParams, ImportMfaUsersParams, UpdateOtpUserParams } from './model/mfaUser';
import { AuditLogRes, GetAccessBrokerParams, GetAuditLogParams, SearchSSOConfigParam, SearchSSOConfigRes } from './model/overview';
import * as ProvisionKeyMgmt from './model/provision-key';
import * as RuleMgmt from './model/rule';
import * as SSLMgmt from './model/ssl';
import * as UserMgmt from './model/user';

export default class Services {
  // User Management
  public static logout(): Promise<UserMgmt.LoginRes> {
    return RESOURCE.delete<UserMgmt.LoginRes>(API.LOGOUT);
  }
  public static getUserList(payload: BaseMgmt.PageParams): Promise<UserMgmt.UserListRes> {
    return RESOURCE.get<UserMgmt.UserListRes>(API.GET_USER_LIST, payload);
  }
  public static updateUserInfo(payload: UserMgmt.UpdateUserParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch<BaseMgmt.BaseRes>(API.UPDATE_USER_INFO_SELF, { body: payload });
  }
  public static getUserInfo(): Promise<UserMgmt.GetUserInfoRes> {
    return RESOURCE.get<UserMgmt.GetUserInfoRes>(API.GET_USER_INFO);
  }
  public static resetAuthenticator(payload: { userId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.RESET_AUTHENTICATOR, payload);
  }
  public static inviteUser(payload: UserMgmt.InviteUserParam, headers?: { organizationId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.INVITE_USER, payload, headers);
  }
  public static deleteUser(payload: { userId: string }, headers?: { organizationId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_USER, payload, headers);
  }
  public static searchUser(payload: UserMgmt.SearchUserParam, urlParams: BaseMgmt.PageParams): Promise<UserMgmt.UserListRes> {
    return RESOURCE.post(API.SEARCH_USER, payload, undefined, urlParams);
  }
  public static changeUserOrg(payload: { userId: string; organizationId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.CHANGE_USER_ORG, { body: payload });
  }
  public static updateUserRole(payload: { userId: string; role: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_USER_ROLE, { body: payload });
  }

  // MFA User Management
  public static searchMfaUsers(payload: BaseMgmt.PageParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.GET_OTP_USER_LIST, payload);
  }
  public static deleteMfaUser(payload: { userId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_OTP_USER, payload);
  }
  public static updateMfaUser(payload: UpdateOtpUserParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_OTP_USER, payload);
  }
  public static createOtpUser(payload: CreateOtpUserParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.CREATE_OTP_USER, payload);
  }
  public static importOtpUsers(payload: ImportMfaUsersParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.upload<BaseMgmt.BaseRes>(API.IMPORT_OTP_USERS, payload);
  }
  public static assignUserToGroup(payload: { groupId: string, userId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.ASSIGN_MFA_USER_TO_GROUP, payload);
  }
  public static removeUserFromGroup(payload: { groupId: string, userId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DE_ASSIGN_MFA_USER_FROM_GROUP, payload);
  }
  public static registerMfaUserAuthenticator(payload: { userId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.REGISTER_MFA_USER_AUTHENTICATOR, payload);
  }
  public static resetMfaUserAuthenticator(payload: { userId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.RESET_MFA_USER_AUTHENTICATOR, payload);
  }

  // MFA Group Management
  public static getAllOtpGroups(): Promise<UserMgmt.UserListRes> {
    return RESOURCE.get<UserMgmt.UserListRes>(API.GET_OTP_GROUPS);
  }
  public static getOtpGroups(payload: BaseMgmt.PageParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_OTP_GROUPS, payload);
  }
  public static createOtpGroup(payload: CreateOtpGroupParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.CREATE_OTP_GROUP, payload);
  }
  public static deleteOtpGroup(payload: { groupId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_OTP_GROUP, payload);
  }
  public static getOtpGroupDetail(payload: { groupId: string }): Promise<GetOtpGroupDetailRes> {
    return RESOURCE.get<GetOtpGroupDetailRes>(API.GET_OTP_GROUP_DETAIL, payload);
  }
  public static updateOtpGroup(payload: CreateOtpGroupParams & { groupId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_OTP_GROUP, payload);
  }
  public static getOtpGroupUsers(payload: BaseMgmt.PageParams & { groupId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.GET_OTP_GROUP_USERS, payload);
  }


  // Application Management
  public static createApplication(payload: ApplicationMgmt.CreateApplicationParams): Promise<ApplicationMgmt.ApplicationRes> {
    return RESOURCE.post<ApplicationMgmt.ApplicationRes>(API.ADD_APPLICATION, payload);
  }
  public static copyApplication(payload: {appId: string; deploymentId: string}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.COPY_APPLICATION, payload);
  }
  public static updateApplication(payload: ApplicationMgmt.Application): Promise<ApplicationMgmt.ApplicationRes> {
    return RESOURCE.put<ApplicationMgmt.ApplicationRes>(API.GET_APPLICATION_DETAIL, payload);
  }
  public static deleteApplication(payload: {appId: string}): Promise<ApplicationMgmt.ApplicationRes> {
    return RESOURCE.delete<ApplicationMgmt.ApplicationRes>(API.GET_APPLICATION_DETAIL, payload);
  }
  public static getApplicationsList(payload: ApplicationMgmt.GetApplicationsParams): Promise<ApplicationMgmt.ApplicationsRes> {
    return RESOURCE.post<ApplicationMgmt.ApplicationsRes>(API.GET_APPLICATIONS_LIST, payload);
  }
  public static getApplicationsOfDeployment(payload: ApplicationMgmt.GetApplicationsParams): Promise<ApplicationMgmt.ApplicationsRes> {
    return RESOURCE.post<ApplicationMgmt.ApplicationsRes>(API.GET_APPLICATIONS_OF_DEPLOYMENT, payload);
  }
  public static getApplicationDetail(payload: GetApplicationDetailParams): Promise<ApplicationMgmt.GetApplicationDetailRes> {
    return RESOURCE.get<ApplicationMgmt.GetApplicationDetailRes>(API.GET_APPLICATION_DETAIL, payload);
  }
  public static getUserProfileSchema(payload: { appId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get(API.GET_USER_PROFILE_SCHEMA, payload);
  }
  public static getSSL(payload: SSLMgmt.GetSSLParams): Promise<SSLMgmt.SSLRes> {
    return RESOURCE.get(API.GET_SSL, payload);
  }
  public static updateSSL(payload: { appId: string } & SSLMgmt.UpdateSSLParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.upload(API.UPDATE_SSL, payload);
  }
  public static updateAdvancedSetting(payload: { appId: string } & ApplicationMgmt.AdvancedSetting): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_APP_ADVANCED_SETTING, { body: payload });
  }
  public static getAdvancedSetting(payload: { appId: string }): Promise<ApplicationMgmt.GetAppAdvancedSettingRes> {
    return RESOURCE.get(API.GET_APP_ADVANCED_SETTING, payload);
  }
  public static updateApplicationTemplate(payload: { appId: string } & ApplicationMgmt.ApplicationTemplate): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_APP_TEMPLATE, { body: payload });
  }
  public static getApplicationTemplate(payload: { appId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get(API.GET_APP_TEMPLATE, payload);
  }
  public static getApplicationKerberos(payload: { appId: string }): Promise<ApplicationMgmt.GetApplicationKerberosRes> {
    return RESOURCE.get(API.GET_APP_KERBEROS, payload);
  }
  public static updateAppKerberos(payload: { appId: string } & ApplicationMgmt.Kerberos): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.upload(API.UPDATE_APP_KERBEROS, payload, HttpMethod.PATCH);
  }
  public static enableApplication(payload: {appId: string}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.ENABLE_APPLICATION, { query: payload });
  }
  public static disableApplication(payload: {appId: string}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.DISABLE_APPLICATION, { query: payload });
  }
  public static getApplicationProfileSelect(payload: {appId: string}): Promise<ApplicationMgmt.GetAppProfileSelectRes> {
    return RESOURCE.get(API.GET_APP_PROFILE_SELECT, payload);
  }
  public static updateApplicationProfileSelect(payload: {appId: string}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put(API.UPDATE_APP_PROFILE_SELECT, payload);
  }


  // Provision Key Management
  public static getProvisionKeys(payload: ProvisionKeyMgmt.GetProvisionKeysParams): Promise<ProvisionKeyMgmt.ProvisionKeysRes> {
    return RESOURCE.get<ProvisionKeyMgmt.ProvisionKeysRes>(API.GET_PROVISION_KEYS_OF_DEPLOYMENT, payload);
  }
  public static createProvisionKey(payload: ProvisionKeyMgmt.CreateProvisionKeyParams): Promise<ProvisionKeyMgmt.ProvisionKeyRes> {
    return RESOURCE.post<ProvisionKeyMgmt.ProvisionKeyRes>(API.ADD_PROVISION_KEY, payload);
  }
  public static revokeProvisionKey(payload: { apiKeyId: number }): Promise<ProvisionKeyMgmt.ProvisionKeyRes> {
    return RESOURCE.delete<ProvisionKeyMgmt.ProvisionKeyRes>(API.DELETE_PROVISION_KEY, payload);
  }

  // Rule Management
  public static createRule(payload: RuleMgmt.CreateRuleParams): Promise<RuleMgmt.RuleRes> {
    return RESOURCE.post<RuleMgmt.RuleRes>(API.ADD_RULE, payload);
  }
  public static getAppRules(payload: GetAppRulesParams): Promise<RuleMgmt.RulesRes> {
    return RESOURCE.post<RuleMgmt.RulesRes>(API.GET_RULES_OF_APPLICATION, payload);
  }
  public static getRuleDetail(payload: { ruleId: string }): Promise<RuleMgmt.RuleRes> {
    return RESOURCE.get<RuleMgmt.RuleRes>(API.GET_RULE_DETAIL, payload);
  }
  public static deleteRule(payload: { ruleId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_RULE, payload);
  }
  public static updateRule(payload: RuleMgmt.CreateRuleParams & { ruleId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_RULE, payload);
  }
  public static delAppRule(payload: { appId: string; ruleId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DEL_APP_RULE, payload);
  }

  // Attribute Pass Management
  public static addAttributePass(payload: AttributeMgmt.AttributePass): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.ADD_ATTRIBUTE_PASS, payload);
  }
  public static getAttributePasses(payload: BaseMgmt.PageParams & { appId: string }): Promise<AttributeMgmt.AttributePassesRes> {
    return RESOURCE.get(API.GET_ATTRIBUTE_PASSES, payload);
  }
  public static deleteAttributePass(payload: { attributePassId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete(API.DELETE_ATTRIBUTE_PASS, payload);
  }
  public static updateAttributePass(payload: AttributeMgmt.AttributePass & { attributePassId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put(API.UPDATE_ATTRIBUTE_PASS, payload);
  }

  // Application Attribute Management
  public static addAppAttribute(payload: AttributeMgmt.Attribute): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.ADD_APP_ATTRIBUTE, payload);
  }
  public static getAppAttributes(payload: BaseMgmt.PageParams & { appId: string }): Promise<AttributeMgmt.AttributesRes> {
    return RESOURCE.get(API.GET_APP_ATTRIBUTES, payload);
  }
  public static deleteAppAttribute(payload: { attributeId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete(API.DELETE_APP_ATTRIBUTE, payload);
  }
  public static updateAppAttribute(payload: AttributeMgmt.UpdateAttributeParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put(API.UPDATE_APP_ATTRIBUTE, payload);
  }
  public static getAttributeMappings(payload: { applicationId: string }): Promise<AttributeMgmt.AttributeMappingsRes> {
    return RESOURCE.get(API.GET_ATTRIBUTE_MAPPINGS, payload);
  }
  public static updateAttributeMappings(payload: Array<AttributeMgmt.UpdateAttributeMapping>): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put(API.UPDATE_ATTRIBUTE_MAPPINGS, payload);
  }

  // Overview Management
  public static getActivities(payload: BaseMgmt.PageParams & { deploymentId?: string; status?: Array<ActivityStatusEnum>; type?: Array<ActivityTypeEnum>; component?: AbComponentEnum }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.GET_ACTIVITIES, payload);
  }
  public static getMetaInfo(): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_META_INFO);
  }
  public static getResourceUsage(): Promise<UserMgmt.ResourceUsageRes> {
    return RESOURCE.get<UserMgmt.ResourceUsageRes>(API.GET_RESOURCE_USAGE);
  }
  public static getAuditLogs(payload: GetAuditLogParams): Promise<AuditLogRes> {
    return RESOURCE.post<AuditLogRes>(API.GET_AUDIT_LOGS, payload);
  }
  public static getAuditLogActors(): Promise<UserMgmt.UserListRes> {
    return RESOURCE.get<UserMgmt.UserListRes>(API.GET_AUDIT_LOG_ACTORS);
  }
  public static invalidateAllRefreshTokens(): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete(API.INVALID_ALL_REFRESH_TOKENS);
  }
  public static getAccessBrokers(payload: GetAccessBrokerParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.GET_ACCESS_BROKERS, payload);
  }

  // Deployment Management
  public static getDeployments(payload: BaseMgmt.PageParams): Promise<DeploymentMgmt.DeploymentsRes> {
    return RESOURCE.get<DeploymentMgmt.DeploymentsRes>(API.GET_DEPLOYMENTS, payload);
  }
  public static duplicateDeployment(payload: {deploymentId: number; name: string}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.DUPLICATE_DEPLOYMENT, payload);
  }
  public static createDeployment(payload: DeploymentMgmt.CreateDeploymentParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.ADD_DEPLOYMENT, payload);
  }
  public static deleteDeployment(payload: { deploymentId: number; cascadeDeleteIdps: boolean }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_DEPLOYMENT, payload);
  }
  public static updateDeployment(payload: DeploymentMgmt.UpdateDeploymentParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_DEPLOYMENT, payload);
  }
  public static getDeploymentDetail(payload: { deploymentId: number }): Promise<DeploymentMgmt.DeploymentRes> {
    return RESOURCE.get<DeploymentMgmt.DeploymentRes>(API.GET_DEPLOYMENT_DETAIL, payload);
  }
  public static getLDAPs(payload: BaseMgmt.PageParams & { hasProfile?: boolean; deploymentStatus?: 'OK' | 'WARNING' }): Promise<LDAPsRes> {
    return RESOURCE.get<LDAPsRes>(API.GET_LDAPS, payload);
  }
  public static addLDAP(payload: CreateLdapParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.ADD_LDAP, payload);
  }
  public static createLdapTestCase(payload: CreateLdapTestCaseParams & { deploymentId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.CREATE_TEST_CASE, payload);
  }
  public static getDeploymentTemplate(payload: { deploymentId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get(API.GET_DEPLOYMENT_TEMPLATE, payload);
  }
  public static updateDeploymentTemplate(payload: DeploymentMgmt.UpdateDeploymentTemplatePrams & { deploymentId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_DEPLOYMENT_TEMPLATE, { body: payload });
  }
  public static updateBranding(payload: DeploymentMgmt.Branding & {deploymentId: string}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.upload(API.UPDATE_DEPLOYMENT_BRANDING, payload, 'PATCH');
  }
  public static getBranding(payload: {deploymentId: string}): Promise<DeploymentMgmt.GetBrandingRes> {
    return RESOURCE.get(API.GET_DEPLOYMENT_BRANDING, payload);
  }
  public static refreshDeploymentConfig(payload: {deploymentId: number}): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.REFRESH_DEPLOYMENT_CONFIG, payload);
  }
  public static updateDeploymentAdvancedSetting(payload: { deploymentId: string } & DeploymentMgmt.DeploymentAdvancedFormInputs): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_DEPLOYMENT_ADVANCED_SETTING, { body: payload });
  }
  public static getDeploymentAdvancedSetting(payload: { deploymentId: string }): Promise<ApplicationMgmt.GetAppAdvancedSettingRes> {
    return RESOURCE.get(API.GET_DEPLOYMENT_ADVANCED_SETTING, payload);
  }

  // LDAP Management
  public static updateLDAP(payload: UpdateLdapParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_LDAP, payload);
  }
  public static deleteLDAP(payload: { ldapId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_LDAP, payload);
  }
  public static getLDAP(payload: { ldapId: string }): Promise<LdapRes> {
    return RESOURCE.get<LdapRes>(API.GET_LDAP, payload);
  }
  public static createLdapMapping(payload: LdapMapping & { ldapId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.ADD_LDAP_MAPPING, payload);
  }
  public static getLdapMappings(payload: { ldapId: string }): Promise<LdapMappingsRes> {
    return RESOURCE.get<LdapMappingsRes>(API.GET_LDAP_MAPPING, payload);
  }
  public static deleteLdapMapping(payload: { ldapId: string; idpId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_LDAP_MAPPING, payload);
  }
  public static updateLdapMapping(payload: LdapMapping & { mappingId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.UPDATE_LDAP_MAPPING, payload);
  }
  public static getLdapTestCase(payload: { testCaseId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_TEST_CASE, payload);
  }

  // ApiToken Management
  public static getAPITokens(payload: BaseMgmt.PageParams): Promise<APITokenMgmt.APITokensRes> {
    return RESOURCE.get<APITokenMgmt.APITokensRes>(API.GET_API_TOKENS, payload);
  }
  public static createAPIToken(payload: APITokenMgmt.CreateApiTokenParams): Promise<APITokenMgmt.APITokenRes> {
    return RESOURCE.post<APITokenMgmt.APITokenRes>(API.ADD_API_TOKEN, payload);
  }
  public static revokeAPIToken(payload: { apiTokenId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_API_TOKEN, payload);
  }

  // MFA Management
  public static getMFAs(payload: GetMFAsParams): Promise<MFAsRes> {
    return RESOURCE.post<MFAsRes>(API.GET_MFAS, payload);
  }
  public static getMFAsOfApplication(payload: GetMFAsParams): Promise<MFAsOfAppRes> {
    return RESOURCE.get<MFAsOfAppRes>(API.GET_MFAS_OF_APPLICATIONS, payload);
  }
  public static createMfa(payload: Record<string, any>): Promise<CreateMfaRes> {
    return RESOURCE.post<CreateMfaRes>(API.ADD_MFA, payload);
  }
  public static assignMfa(payload: { mfaId: string; appId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.ASSIGN_MFA, payload);
  }
  public static deleteMfa(payload: { mfaId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_MFA, payload);
  }
  public static unAttachMfa(payload: { appId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.UN_ATTACH_MFA, payload);
  }
  public static updateMfa(payload: Record<string, any>): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_MFA, payload);
  }
  public static getMfaDetail(payload: { mfaId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_MFA_DETAIL, payload);
  }

  // IdP Management
  public static getIdPs(payload: IdPMgmt.GetIdPsParams): Promise<IdPMgmt.IdPsRes> {
    return RESOURCE.post<IdPMgmt.IdPsRes>(API.GET_IDPS, payload);
  }
  public static getIdPsOfApplication(payload: IdPMgmt.GetIdPsParams): Promise<IdPMgmt.IdPsRes> {
    return RESOURCE.post<IdPMgmt.IdPsRes>(API.GET_IDPS_OF_APPLICATIONS, payload);
  }
  public static createIdP(payload: Record<string, any>): Promise<IdPMgmt.CreateIdpRes> {
    return RESOURCE.upload<IdPMgmt.CreateIdpRes>(API.ADD_IDP, payload);
  }
  public static assignIdP(payload: { idpId: string; appId: string; type: IdPTypeEnum }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.ASSIGN_IDP, payload);
  }
  public static createAndAssignIdP(payload: Record<string, any>): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.upload<BaseMgmt.BaseRes>(API.CREATE_AND_ASSIGN_IDP, payload);
  }
  public static deleteIdP(payload: { idpId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.DELETE_IDP, payload);
  }
  public static unAttachIdP(payload: { idpId: string; appId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete<BaseMgmt.BaseRes>(API.UN_ATTACH_IDP, payload);
  }
  public static updateIdP(payload: Record<string, any>): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.upload<BaseMgmt.BaseRes>(API.UPDATE_IDP, payload, 'PUT');
  }
  public static getIdpDetail(payload: { idpId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_IDP_DETAIL, payload);
  }

  // Organization Management
  public static createOrganizationAndUser(): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post<BaseMgmt.BaseRes>(API.CREATE_ORGANIZATION);
  }
  public static getOrganization(): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_ORGANIZATION);
  }
  public static getAllOrganizations(): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get<BaseMgmt.BaseRes>(API.GET_ALL_ORGANIZATIONS);
  }
  public static updateOrganization(payload: { name: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put<BaseMgmt.BaseRes>(API.UPDATE_ORGANIZATION, payload);
  }

  // Setting Management
  public static getSettings(): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get(API.GET_SETTINGS);
  }
  public static updateSettings(payload: { isMFA: boolean }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_SETTINGS, { body: payload });
  }
  public static updateMaxUsage(payload: UserMgmt.UpdateUsageParams): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.patch(API.UPDATE_MAX_USAGE, { body: payload });
  }
  public static searchSSOConfig(payload: SearchSSOConfigParam): Promise<SearchSSOConfigRes> {
    return RESOURCE.post(API.SEARCH_SSO_CONFIG, payload);
  }

  // Authorizer Management
  public static getAuthorizers(payload: BaseMgmt.PageParams): Promise<AuthorizersRes> {
    return RESOURCE.get(API.GET_AUTHORIZERS, payload);
  }
  public static createAuthorizer(payload: CreateAuthorizer): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.post(API.CREATE_AUTHORIZER, payload);
  }
  public static updateAuthorizer(payload: CreateAuthorizer & { authorizerId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.put(API.UPDATE_AUTHORIZER, payload);
  }
  public static deleteAuthorizer(payload: { authorizerId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.delete(API.DELETE_AUTHORIZER, payload);
  }
  public static getAuthorizerDetail(payload: { authorizerId: string }): Promise<BaseMgmt.BaseRes> {
    return RESOURCE.get(API.GET_AUTHORIZER_DETAIL, payload);
  }
}
